// TODO: REFACTORING
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { useMediaQuery } from 'react-responsive';

import parseSharedFlightData from '@utils/parseSharedFlightData';
import SharedFlightInfo from '@components/SharedFlightInfo/SharedFlightInfo';
import Styles from './style.module.css';
import 'swiper/css/swiper.min.css';
import './swiper.config.css';

function SharedCarousel({ flights, onSelect }) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const swiper = useRef();
  const selectedFlight = useRef();

  const swiperParams = useMemo(() => {
    return {
      on: {
        slideChange: () => {
          const mySwiper = swiper?.current?.swiper;
          selectedFlight.current = flights[mySwiper.activeIndex].id;
          onSelect(selectedFlight.current);
        },
      },
      // containerModifierClass: Styles.carouselContainer,
      direction: isMobile ? 'horizontal' : 'vertical',
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: !isMobile ? 30 : 0,
      shouldSwiperUpdate: false,
      mousewheel: true,
      height: isMobile ? 280 : 124,
      preventClicks: false,
      preventClicksPropagation: false,
      touchStartPreventDefault: false,
    };
  }, []);

  useEffect(() => {
    selectedFlight.current = flights[0].id;
  }, []);

  function createSwaper() {
    return (
      <div className={Styles.carouselContainer}>
        <Swiper {...swiperParams} ref={swiper}>
          {flights.map(flight => (
            <div
              className={!isMobile ? Styles.item : Styles.itemMobile}
              key={flight.id}
            >
              <SharedFlightInfo
                data={parseSharedFlightData(flight)}
                theme={'origin'}
                variant={'carousel'}
              />
            </div>
          ))}
        </Swiper>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className={`shared-flights-carousel ${Styles.wrapperMobile}`}>
        {createSwaper()}
      </div>
    );
  }

  return (
    <div className={`shared-flights-carousel ${Styles.wrapper}`}>
      {createSwaper()}
      <div className={Styles.empty} key={'empty'} />
    </div>
  );
}

SharedCarousel.propTypes = {
  flights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SharedCarousel;
